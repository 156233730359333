import { render, staticRenderFns } from "./HomeInsurance.vue?vue&type=template&id=5db6fd2b&scoped=true"
import script from "./HomeInsurance.vue?vue&type=script&lang=js"
export * from "./HomeInsurance.vue?vue&type=script&lang=js"
import style0 from "./HomeInsurance.vue?vue&type=style&index=0&id=5db6fd2b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db6fd2b",
  null
  
)

export default component.exports