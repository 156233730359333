<template>
  <v-container
    fluid
    class="pa-0 white"
  >
    <div class="hidden-sm-and-down">
      <div
        class="section1 pb-16 small"
      >
        <sequential-entrance from-top>
          <v-row class="pt-16 ml-16">
            <v-col class="pt-16 mt-16">
              <div class="title-text-small">
                Get a <span class="primary--text">home</span> insurance <br> quote and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance from-bottom>
          <v-row
            class="ml-12"
          >
            <v-col
              cols="6"
              class="d-inline-flex align-center"
            >
              <v-img
                src="/img/home-quote.png"
                class="travel-qt"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold"
                to="/homeowners/info"
                large
              >
                <span class="px-4">Home Quote</span>
              </v-btn>
              <v-btn
                class="white learn font-weight-bold"
                to="/homeowners/learnmore"
                large
              >
                <span class="secondary--text">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16">
        <v-row class="pt-16">
          <v-col
            class="d-flex justify-center"
          >
            <div
              class="travel-text pa-4"
            >
              <span class="product-text">OUR PRODUCTS</span> <br>
              Find insurance coverage you need
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pb-4">
          <v-col
            cols="12"
            sm="9"
            md="9"
            class="text-center"
          >
            <div class="product-subtext">
              Blanket is partnered with some of the leading providers in the insurance world and strives to give you the best coverage and customer experience.
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-sheet max-width="90%">
            <v-slide-group
              show-arrows
            >
              <v-slide-item
                v-for="(item, index) in productLinks"
                :key="index"
              >
                <v-card
                  flat
                  @click="goTo(item.route)"
                >
                  <v-img
                    :src="item.img"
                    class="mx-auto zoom"
                    height="180"
                    width="180"
                  />
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-row>
      </div>
    </div>
    <!------------------------------------------TABLET------------------------------------------------------>
    <div v-show="tabletDevice">
      <div
        class="section1-tablet pb-16"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="d-flex justify-center">
            <v-col>
              <div class="title-text-tablet mt-16 pt-10 ml-6">
                Get a <span class="primary--text">home</span> insurance <br> quote and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance from-bottom>
          <v-row>
            <v-col
              cols="12"
              class="d-inline-flex align-center mt-6"
            >
              <v-img
                src="/img/home-quote.png"
                class="travel-badge-tablet"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold"
                to="/homewoners/info"
                large
              >
                <span class="travel-quote-tablet">Home Quote</span>
              </v-btn>
              <v-btn
                class="white learn font-weight-bold"
                to="/homeowners/learnmore"
                large
              >
                <span class="learn-more-tablet">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16">
        <v-row class="pt-4 d-flex-justify-center">
          <v-col
            class="d-flex justify-center"
          >
            <div class="text-center">
              <span class="product-text-tablet">Our Products</span>
              <br>
              <span class="coverage-text-tablet">Find insurance coverage you need</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-8 mb-8">
          <v-col cols="9">
            <div class="product-subtext-tablet">
              Blanket is partnered with some of the leading providers in the insurance world and strives to give you the best coverage and customer experience.
            </div>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center mx-2"
        >
          <v-col cols="12">
            <v-slide-group
              show-arrows
              multiple
            >
              <v-card
                v-for="(item, index) in productLinks"
                :key="index"
                flat
                class="px-4"
                @click="goTo(item.route)"
              >
                <v-img
                  :src="item.img"
                  height="200"
                  width="200"
                />
              </v-card>
            </v-slide-group>
          </v-col>
        </v-row>
      </div>
    </div>
    <!------------------------------------------MOBILE------------------------------------------------------>
    <div v-show="mobileDevice">
      <div
        class="section1-mobile pb-16"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="d-flex justify-center">
            <v-col>
              <div class="title-text-mobile mt-n4">
                Get a <span class="primary--text">home</span> insurance <br> quote and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance from-bottom>
          <v-row class="d-flex justify-center">
            <v-col
              cols="9"
              class="d-flex align-center justify-center mt-2 ml-4"
            >
              <v-btn
                class="primary white--text font-weight-bold"
                to="/homeowners/info"
                block
                large
              >
                <span class="travel-quote-mobile">Home Quote</span>
              </v-btn>
            </v-col>
            <v-col
              cols="9"
              class="mt-0 pt-0"
            >
              <v-btn
                class="white learn font-weight-bold"
                to="/homeowners/learnmore"
                block
                large
              >
                <span class="learn-more-mobile">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16 pt-16">
        <v-row class="pt-4 d-flex-justify-center">
          <v-col
            class="d-flex justify-center"
          >
            <div class="text-center">
              <span class="product-text-mobile">Our Products</span>
              <br>
              <span class="coverage-text-mobile">Find insurance coverage you need</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <div class="product-subtext-mobile">
              Blanket is partnered with some of the leading providers in the insurance world and strives to give you the best coverage and customer experience.
            </div>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center"
        >
          <v-col
            cols="12"
          >
            <v-slide-group
              multiple
              show-arrows
            >
              <v-card
                v-for="(item, index) in productLinks"
                :key="index"
                flat
                @click="goTo(item.route)"
              >
                <v-img
                  :src="item.img"
                  height="140"
                  width="140"
                />
              </v-card>
            </v-slide-group>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Dashboard',

    data () {
      return {
        productLinks: [
          { title: 'Auto', route: '/auto', img: '/img/product-chips/car.png' },
          { title: 'Travel', route: '/travel', img: '/img/product-chips/travel.png' },
          { title: 'Pet', route: '/pet', img: '/img/product-chips/pet.png' },
          { title: 'Termmlife', route: '/termlifehome', img: '/img/product-chips/termlife.png' },
          { title: 'Business', route: '/business', img: '/img/product-chips/business.png' },
          { title: 'Condo', route: '/condo', img: '/img/product-chips/condo.png' },
          { title: 'Landlord', route: '/landlord', img: '/img/product-chips/landlord.png' },
          { title: 'Renters', route: '/renters', img: '/img/product-chips/renters.png' },
        ],
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },
    created () {
      this.$gtag.pageview('Travel USA')
      this.currentuser = this.$store.getters.getuser
    },

    methods: {
      goTo (route) {
        this.$router.push({ path: route })
      },
      navigateTo (product) {
        this.$router.push(product)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
.product-subtext {
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 132.19%;
/* or 32px */

text-align: center;

/* Gray/600 */

color: #757575;
  }
/*------------------------------MOBILE----------------------------------------------------*/
.product-subtext-mobile {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 132.19%;
/* or 19px */

text-align: center;

/* Gray/600 */

color: #757575;
}
.product-text-mobile {
font-family: Roboto;
font-size: 20px;
font-weight: 300;
line-height: 23px;
letter-spacing: -0.005em;
text-align: center;
color: #757575;
}
.coverage-text-mobile {
font-family: Roboto;
font-size: 32px;
font-weight: 400;
line-height: 38px;
letter-spacing: -0.005em;
text-align: center;
color: #757575;
}
.learn-more-mobile {
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.00892857em;
text-transform: uppercase;

/* Secondary */

color: #005F6C;
}
.travel-quote-mobile {
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.00892857em;
text-transform: uppercase;
color: #FFFFFF;
}
.title-text-mobile {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 125.19%;
/* or 40px */

text-align: center;
letter-spacing: -0.04em;

/* Gray/700 */

color: #616161;
  }
  .section1-mobile{
  position: relative;
  height: 85vh;
  top: 50px;
  background: linear-gradient(to top, rgba(255,255,255,0) 70%, rgba(255,255,255,1) 100%),url( '/img/travel-usa-background-mobile.jpg') no-repeat center center;
  background-size: cover;
  background-color: white;
  background-position: 75%;
}
/*------------------------------TABLET----------------------------------------------------*/
.product-subtext-tablet {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 132.19%;
/* or 26px */

text-align: center;

/* Gray/600 */

color: #757575;
}
.product-text-tablet {
  font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 38px;
letter-spacing: -0.005em;
text-transform: uppercase;

/* Gray/600 */

color: #757575;
}

.coverage-text-tablet {
  font-family: Roboto;
font-size: 50px;
font-weight: 400;
line-height: 59px;
letter-spacing: -0.005em;
color: #757575;
}
.learn-more-tablet {
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;

color: #005F6C;
}
.travel-quote-tablet{
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 132.19%;
/* identical to box height, or 24px */

display: flex;
align-items: center;
text-transform: uppercase;

/* white */

color: #FFFFFF;
}
.travel-badge-tablet {
  max-width:  140px;
  object-fit: scale-down;
  margin-right: -3rem;
  z-index: 5;
}
  .title-text-tablet {
    font-family: forma-djr-deck;
    font-style: normal;
    font-weight: 500;
    font-size: 55px;
    line-height: 125.19%;
    /* or 69px */

    letter-spacing: -0.04em;

    /* Gray/700 */

  color: #616161;
  }

.title-text-small {
font-family: forma-djr-deck;
font-style: normal;
font-weight: 500;
font-size: 60px;
line-height: 132.19%;
/* or 79px */
color: #616161;
  }

.title-text-large {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
  margin-top: 6rem;
  }

  .section1{
  position: relative;
  width: 100%;
  height: 700px;
  background: url("/img/homeowners-background.png") no-repeat;
  background-size: cover;
  background-position: 0% 50%;
}

.section1-tablet{
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("/img/homeowners-tablet-background.png") no-repeat;
  background-size: contain;
  background-position: 0% 25vh;
}

.section2{
  background-color: #FFFFFF;
}

.large {
    height: 900px;
}
.travel-qt {
  max-width:  120px;
  object-fit: scale-down;
  margin-right: -4rem;
  z-index: 5;
}

.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}

.travel-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
background-color: white;
}

.zoom {
    transition: transform .2s;
}

.zoom:hover {
    transform: scale(1.2);
}

.product-text {
  font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 36px;
line-height: 42px;
text-align: center;
letter-spacing: -0.005em;
text-transform: uppercase;

/* Gray/600 */

color: #757575;
}

</style>
